import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { RouteLayout, ScrollToTop } from './Components'
import BlankLayout from './Components/Layouts/BlankLayout'
import WithHeader from './Components/Layouts/WithHeader'
import ColorContext from './Context/colorContext'
import { SocialProvider } from './Context/socialContext'
import LoginCallback from './Pages/LoginCallback'
import ManifestationDetailPrint from './Pages/ManifestationDetailPrint'
import ManifestationPrint from './Pages/ManifestationPrint'
import NotFound from './Pages/NotFound'
import SchedulePrint from './Pages/SchedulePrint'
import ServiceInfoPrint from './Pages/ServiceInfoPrint'

const Accessibility = React.lazy(() => import('./Pages/Accessibility'))
const AttendanceOnlineChat = React.lazy(
  () => import('./Pages/AttendanceOnlineChat'),
)
const CategoryList = React.lazy(
  () => import('./Pages/Categorias/components/CategoryList'),
)
const CategoyService = React.lazy(() => import('./Pages/CategoryService'))
const Demand = React.lazy(() => import('./Pages/Demand'))
const EnterpriseService = React.lazy(() => import('./Pages/EnterpriseService'))
const FAQ = React.lazy(() => import('./Pages/FAQ'))
const Home = React.lazy(() => import('./Pages/Home'))
const ManifestationDetail = React.lazy(
  () => import('./Pages/ManifestationDetail'),
)
const MyAttendences = React.lazy(() => import('./Pages/MyAttendences'))
const MyInformations = React.lazy(() => import('./Pages/MyInformations'))
const MySchedules = React.lazy(() => import('./Pages/MySchedules'))
const NewEsic = React.lazy(() => import('./Pages/NewEsic'))
const NewManifestation = React.lazy(() => import('./Pages/NewManifestation'))
const News = React.lazy(() => import('./Pages/News'))
const Onbudsman = React.lazy(() => import('./Pages/Onbudsman'))
const OrganDetail = React.lazy(() => import('./Pages/OrganDetail'))
const OrgansList = React.lazy(() => import('./Pages/OrgansList'))
const PersonService = React.lazy(() => import('./Pages/PersonService'))
const PublicAdministrationService = React.lazy(
  () => import('./Pages/PublicAdministrationService'),
)
const QuickAccess = React.lazy(() => import('./Pages/QuickAccess'))
const SeachService = React.lazy(() => import('./Pages/SearchService'))
const SearchManifestation = React.lazy(
  () => import('./Pages/SearchManifestation'),
)
const ServerService = React.lazy(() => import('./Pages/ServerService'))
const ServiceInfo = React.lazy(() => import('./Pages/ServiceInfo'))
const SiteMap = React.lazy(() => import('./Pages/SiteMap'))
const Terms = React.lazy(() => import('./Pages/Terms'))
const Workspace = React.lazy(() => import('./Pages/Workspace'))
const newDetail = React.lazy(() => import('./Pages/NewDetail'))
const AppInfo = React.lazy(() => import('./Pages/AppInfo'))
const DocumentValidation = React.lazy(
  () => import('./Pages/DocumentValidation'),
)
const PagesComponent = React.lazy(() => import('./Pages/Home/components/Pages'))
const ListApp = React.lazy(() => import('./Pages/ListApp'))
const ListServices = React.lazy(() => import('./Pages/ListServices'))
const ListSubCategory = React.lazy(() => import('./Pages/ListSubCategory'))
const PageAndroid = React.lazy(() => import('./Pages/PageAndroid'))
const PageIOS = React.lazy(() => import('./Pages/PageIOS'))
const PageMap = React.lazy(() => import('./Pages/PageMap'))
const Photos = React.lazy(() => import('./Pages/Photos'))
const Reschedule = React.lazy(() => import('./Pages/Reschedule'))
const Secretaries = React.lazy(() => import('./Pages/Secretaries'))
const SimplifiqueFlow = React.lazy(() => import('./Pages/SimplifiqueFlow'))
const VideoList = React.lazy(() => import('./Pages/VideoList'))

interface ColorContextProps {
  accentColor: string
  accentColorBackground: string
  colorBackground: string
  colorBackgroundSecundary: string
  textBody: string
  textAccentColor: string
  badgeColor: string
  cardAccentColor: string
  accordionTextColor: string
  serviceCardBackgroundColor: string
  favoriteBoxColor: string
  breadcrumbButtonColorHover: string
  borderShadowsColor: string
  buttonSuccessColor: string
  buttonErrorColor: string
  accentColorSecondary: string
  iconStepColor: string
  stepLabelColor: string
  menuItemColor: string
  subTitleColor: string
  colorGradient: string
  searchInputColor: string
  colorWithoutGradient: string
  colorSecondary: string
  borderShadowColorSecondary: string
  colorRJWithoutGradient: string
  colorPrimaryButton: string
  textBodyBack: string
  colorCarousel: string
  colorBreadcrumb: string
}

function App(): JSX.Element {
  const [typeTheme, setTypeTheme] = useState<string>('normal')

  const handleThemeColor = useCallback((type: string) => {
    localStorage.setItem('@portalunico/themeColor', type)
    setTypeTheme(type)
  }, [])

  const [colors, setColors] = useState<ColorContextProps>({
    accentColor: process.env.REACT_APP_COLOR_PRIMARY,
    accentColorBackground: process.env.REACT_APP_COLOR_PRIMARY,
    colorBackground: process.env.REACT_APP_COLOR_SECONDARY,
    colorBackgroundSecundary: '#ffffff',
    textBody: '#6B6D6E',
    textAccentColor: '#ffffff',
    badgeColor: process.env.REACT_APP_COLOR_PRIMARY,
    cardAccentColor: process.env.REACT_APP_COLOR_PRIMARY,
    accordionTextColor: '#272727',
    serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
    favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
    breadcrumbButtonColorHover: '#d4d4d4',
    borderShadowsColor: '#E1E6EF',
    buttonSuccessColor: '#3CD278',
    buttonErrorColor: '#F03D3D',
    accentColorSecondary: process.env.REACT_APP_COLOR_SECONDARY,
    iconStepColor: '#94C120',
    stepLabelColor: '#ccc',
    menuItemColor: '#d4d4d4',
    subTitleColor: '#706E7A',
    colorGradient: 'linear-gradient(90deg, #192844 67.81%, #472774 100%)',
    searchInputColor: 'rgba(255, 255, 255, 0.85)',
    colorWithoutGradient: '#192844',
    colorSecondary: '#442771',
    borderShadowColorSecondary: '#00508C',
    colorRJWithoutGradient: '#192844',
    colorPrimaryButton: '#0BBBEF',
    textBodyBack: '#000000',
    colorCarousel: '#EEEEEE',
    colorBreadcrumb: '#192644',
  })

  useEffect(() => {
    switch (typeTheme) {
      case 'normal':
        setColors({
          accentColor: process.env.REACT_APP_COLOR_PRIMARY,
          accentColorBackground: process.env.REACT_APP_COLOR_PRIMARY,
          colorBackground: '#ffffff',
          colorBackgroundSecundary: '#192844',
          textBody: '#333333',
          textBodyBack: '#000000',
          textAccentColor: '#ffffff',
          badgeColor: process.env.REACT_APP_COLOR_PRIMARY,
          cardAccentColor: process.env.REACT_APP_COLOR_PRIMARY,
          accordionTextColor: '#272727',
          serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
          favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
          breadcrumbButtonColorHover: '#d4d4d4',
          borderShadowsColor: '#E1E6EF',
          buttonSuccessColor: '#3CD278',
          buttonErrorColor: '#F03D3D',
          accentColorSecondary: '#94C120',
          iconStepColor: '#94C120',
          stepLabelColor: '#ccc',
          menuItemColor: '#d4d4d4',
          subTitleColor: '#706E7A',
          colorGradient: 'linear-gradient(90deg, #192844 67.81%, #472774 100%)',
          searchInputColor: 'rgba(255, 255, 255, 0.85)',
          colorWithoutGradient: '#192844',
          colorSecondary: '#442771',
          borderShadowColorSecondary: '#00508C',
          colorRJWithoutGradient: '#192844',
          colorPrimaryButton: '#0BBBEF',
          colorCarousel: '#EEEEEE',
          colorBreadcrumb: '#192644',
        })
        break

      case 'contraste':
        setColors({
          accentColor: '#FFFF1A',
          accentColorBackground: '#1F1F1F',
          colorBackground: '#121212',
          colorBackgroundSecundary: '#121212',
          textBody: '#FFFF1A',
          textAccentColor: '#FFFF1A',
          badgeColor: '#121212',
          cardAccentColor: '#1F1F1F',
          accordionTextColor: '#FFFF1A',
          serviceCardBackgroundColor: '#1F1F1F',
          favoriteBoxColor: '#1F1F1F',
          breadcrumbButtonColorHover: '#1F1F1F',
          borderShadowsColor: '#FFFF1A',
          buttonSuccessColor: '#FFFF1A',
          buttonErrorColor: '#FFFF1A',
          accentColorSecondary: '#FFFF1A',
          iconStepColor: '#1F1F1F',
          stepLabelColor: '#ccc',
          menuItemColor: '#121212',
          subTitleColor: '#FFFF1A',
          colorGradient: '#1F1F1F',
          searchInputColor: '#121212',
          colorWithoutGradient: '#1F1F1F',
          colorSecondary: '#FFFF1A',
          borderShadowColorSecondary: '#FFFF1A',
          colorRJWithoutGradient: '#FFFF1A',
          colorPrimaryButton: '#1F1F1F',
          textBodyBack: '#FFFF1A',
          colorCarousel: '#1F1F1F',
          colorBreadcrumb: '#1F1F1F',
        })
        break

      default:
        break
    }
  }, [typeTheme])

  useEffect(() => {
    const getThemeLocal = () => {
      const theme = localStorage.getItem('@portalunico/themeColor')
      const themeInLocal = theme || 'normal'
      setTypeTheme(themeInLocal)
    }

    getThemeLocal()
  }, [])

  return (
    <ColorContext.Provider
      value={{ colors, setTypeTheme: handleThemeColor, typeTheme }}
    >
      <SocialProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <RouteLayout path="/" exact component={Home} layout={WithHeader} />
            <RouteLayout
              path="/buscar/q=:slug"
              exact
              component={SeachService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/servico/:slug"
              exact
              component={ServiceInfo}
              layout={WithHeader}
            />
            <Route
              path="/servico/:slug/imprimir"
              exact
              component={ServiceInfoPrint}
            />
            <RouteLayout
              path="/servicos/:slug/presencial=:modal"
              exact
              component={ServiceInfo}
              layout={WithHeader}
            />
            <RouteLayout
              path="/cidadao/servicos"
              exact
              component={PersonService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/empresa/servicos"
              exact
              component={EnterpriseService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/servidor/servicos"
              exact
              component={ServerService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/gestao_publica/servicos"
              exact
              component={PublicAdministrationService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/:type_service/servicos/:slug/categoria/:id"
              exact
              component={ListServices}
              layout={WithHeader}
            />
            <RouteLayout
              path="/:type_service/servicos/:slug/sub_categoria/:id"
              exact
              component={ListSubCategory}
              layout={WithHeader}
            />
            <RouteLayout
              path="/categoria/:slug"
              exact
              component={CategoyService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/categorias"
              exact
              component={CategoryList}
              layout={WithHeader}
            />
            <RouteLayout
              path="/meus_agendamentos"
              exact
              component={MySchedules}
              layout={WithHeader}
              isAuth
            />
            <RouteLayout
              path="/meus_atendimentos"
              exact
              component={MyAttendences}
              layout={WithHeader}
              isAuth
            />

            <Route path="/login/callback/" component={LoginCallback} />
            <RouteLayout
              exact
              path="/videos"
              layout={WithHeader}
              component={VideoList}
            />
            <RouteLayout
              exact
              path="/fotos"
              layout={WithHeader}
              component={Photos}
            />
            <RouteLayout
              exact
              path="/secretarias"
              layout={WithHeader}
              component={Secretaries}
            />
            <RouteLayout
              exact
              path="/orgaos"
              layout={WithHeader}
              component={OrgansList}
            />
            <RouteLayout
              exact
              path="/aplicativo/:slug"
              layout={WithHeader}
              component={AppInfo}
            />
            <RouteLayout
              exact
              path="/aplicativos"
              layout={WithHeader}
              component={ListApp}
            />
            <RouteLayout
              exact
              path="/orgao/:slug"
              layout={WithHeader}
              component={OrganDetail}
            />
            <RouteLayout
              exact
              path="/acessibilidade"
              layout={WithHeader}
              component={Accessibility}
            />
            <RouteLayout
              exact
              path="/sitemap"
              layout={WithHeader}
              component={SiteMap}
            />
            <Route
              exact
              path="/agendamento/comprovante/:id"
              component={SchedulePrint}
            />
            <RouteLayout
              exact
              path="/rjdigital-android"
              component={PageAndroid}
              layout={WithHeader}
            />
            <RouteLayout
              exact
              path="/rjdigital-ios"
              component={PageIOS}
              layout={WithHeader}
            />
            <RouteLayout
              exact
              path="/atendimento_online/:id"
              layout={WithHeader}
              component={AttendanceOnlineChat}
            />
            <RouteLayout
              exact
              path="/simplifique"
              layout={WithHeader}
              component={SimplifiqueFlow}
            />
            <RouteLayout
              exact
              path="/noticias"
              layout={WithHeader}
              component={News}
            />
            <RouteLayout
              exact
              path="/noticias/:slug"
              layout={WithHeader}
              component={newDetail}
            />
            <RouteLayout
              exact
              path="/ouvidoria/:slug?/"
              layout={WithHeader}
              component={Onbudsman}
            />
            <RouteLayout
              exact
              path="/ouvidoria-faq"
              layout={WithHeader}
              component={FAQ}
            />
            <RouteLayout
              exact
              path="/manifestacao/:slug/:slugParam?/"
              layout={WithHeader}
              component={NewManifestation}
            />
            <RouteLayout
              exact
              path="/manifestacao/:slug/esic_protocolo/:protocolo"
              layout={WithHeader}
              component={NewManifestation}
            />
            <RouteLayout
              exact
              path="/esic"
              layout={WithHeader}
              component={NewEsic}
            />
            <RouteLayout
              exact
              path="/consultar_manifestacao"
              layout={WithHeader}
              component={SearchManifestation}
            />
            <RouteLayout
              exact
              path="/demanda/:slug"
              layout={WithHeader}
              component={Demand}
            />
            <Route
              path="/manifestacao/:codigo/:protocolo/imprimir"
              exact
              component={ManifestationPrint}
            />
            <Route
              path="/detalhes_manifestacao/:codigo/:protocolo/imprimir"
              exact
              component={ManifestationDetailPrint}
            />
            <RouteLayout
              exact
              path="/detalhes_manifestacao/numero=:protocolo&codigo=:codigo"
              layout={WithHeader}
              component={ManifestationDetail}
            />
            <RouteLayout
              exact
              path="/pagina/:slug"
              layout={WithHeader}
              component={PagesComponent}
            />

            {/* <RouteLayout exact path="/buscar_pedido" layout={WithHeader} component={SearchServiceInfo} />
            <RouteLayout exact path="/buscar_pedido/:protocolo/detalhes" layout={WithHeader} component={SearchServiceDetails} /> */}

            <RouteLayout
              exact
              path="/validacao_documento/:hash?/:id?"
              layout={WithHeader}
              component={DocumentValidation}
            />
            <RouteLayout
              exact
              path="/Uploads/Noticias/mapa-rio.webp"
              layout={BlankLayout}
              component={PageMap}
            />

            <RouteLayout
              exact
              path="/workspace"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/termos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/solicitacoes"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/notificacoes"
              exact
              isAuth
              component={BlankLayout}
              layout={Workspace}
            />
            <RouteLayout
              path="/workspace/categorias"
              exact
              isAuth
              component={BlankLayout}
              layout={Workspace}
            />
            <RouteLayout
              path="/workspace/minha-conta"
              exact
              isAuth
              component={BlankLayout}
              layout={Workspace}
            />
            <RouteLayout
              path="/workspace/poupatempo"
              exact
              isAuth
              component={BlankLayout}
              layout={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/avaliacoes"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/meus_servicos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/permissoes"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/minhas_solicitacoes_servico"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/meus_agendamentos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/atividades"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/prova-de-vida"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/historico-prova-de-vida"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/minha-area"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/minha-conta/privacidade"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/minha-conta/meus-dados"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/minha-area/privacidade"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/minha-area/meus-dados"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/favoritos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/avaliacoes/servicos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/avaliacoes/agendamentos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/avaliacoes/ouvidoria"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/servico-digital"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/formularios/:slug"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/servico-digital/:tema_slug/:tema"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/atendimento_online/:id"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/meus_servicos/integrado/:type"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/seguranca"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              path="/workspace/meu_simplifique"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />

            <RouteLayout
              path="/workspace/minhas_solicitacoes"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/minhas_manifestacoes"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/minhas_manifestacoes/imprimir/:id"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />

            <RouteLayout
              path="/workspace/meus_pedidos"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/meus_pedidos/imprimir/:id"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />

            <RouteLayout
              path="/workspace/minhas_solicitacoes/view_doc/:uuid_doc/:identificador_solcitacao"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />

            <RouteLayout
              exact
              path="/workspace/avaliar_atendimento_presencial/:ticket"
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/avaliar_atendimento_interno/:ticket"
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              path="/workspace/avaliar_atendimento_online/:ticket"
              exact
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/avaliar_atendimento_diario/:ticket"
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/avaliar_simplifique/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/avaliar_esic/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/avaliar_solicitacao_servico/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/avaliar_manifestacao/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/avaliar_fluxo/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              path="/workspace/minha-area-servicos"
              exact
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/acesso-rapido"
              isAuth
              layout={WithHeader}
              component={QuickAccess}
            />
            <RouteLayout
              exact
              path="/termos"
              isAuth
              layout={WithHeader}
              component={Terms}
            />
            <RouteLayout
              exact
              path="/meus_dados"
              isAuth
              layout={WithHeader}
              component={MyInformations}
            />
            <RouteLayout
              path="/reagendamentos/:url"
              exact
              component={Reschedule}
              layout={BlankLayout}
            />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </SocialProvider>
    </ColorContext.Provider>
  )
}

export default App
